import styled from 'styled-components';
import { colors, media, spacing, toRem, zIndex } from '@styles/Theme';

import { H5 } from '@styles/Typography';

import stepsMark from '@images/steps-mark.svg';
import drugsMark from '@images/drugs-mark.svg';
// import icon from '@images/clinic-condition-common.svg';

export const HowToInnerItem = styled.div`
	position: relative;
	padding-left: ${spacing(4)};
`;

export const HowToItem = styled.div`
	position: relative;

	&:not(:last-child) {
		${HowToInnerItem} {
			padding-bottom: ${spacing(4)};

			&::before,
			&::after {
				position: absolute;
				display: block;
				content: '';
			}

			&::before {
				left: 0;
				width: 2px;
				height: 100%;
				border-left: 2px dashed #ccc;
			}

			&::after {
				top: 0;
				left: 0;
				width: 2px;
				height: 100%;
				background: transparent;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0) 49%,
					rgba(255, 255, 255, 1) 100%
				);
			}
		}
	}

	@media (${media.tablet}) {
		padding-left: ${spacing(12)};

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: ${spacing(6)};
			height: ${spacing(6)};
			background: url(${({ icon }) => icon}) no-repeat;
			background-size: contain;
			content: '';
		}
	}
`;

export const HowToTitle = styled(H5)`
	position: relative;

	&::before {
		position: absolute;
		top: ${spacing(0.5)};
		left: 0;
		z-index: ${zIndex.floated};
		width: ${spacing(2)};
		height: ${spacing(2)};
		background: url(${stepsMark}) no-repeat;
		background-size: cover;
		transform: translateX(calc(${spacing(-6)} + 10px));
		filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
		content: '';
	}
`;

export const DrugsTitle = styled(H5)``;

export const DrugList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const DrugItem = styled.li`
	position: relative;
	width: calc(100% / 2 - ${spacing(2)});
	padding-left: ${spacing(4)};
	font-weight: bold;
	font-size: ${toRem(18)};

	&:not(:nth-child(-n + 2)) {
		margin-top: ${spacing(4)};
	}

	&::before {
		position: absolute;
		left: 0;
		display: block;
		width: ${spacing(2)};
		height: ${spacing(2)};
		background: url(${drugsMark}) no-repeat;
		background-size: cover;
		content: '';
	}
`;

export const ContentBox = styled.div`
	position: relative;
	padding-bottom: ${spacing(6)};
	text-align: center;

	&::before {
		position: absolute;
		bottom: 0;
		left: 50%;
		display: block;
		width: ${spacing(8)};
		height: ${spacing(0.5)};
		background-color: ${colors.primary};
		content: '';
	}

	@media (${media.mobileAndTablet}) {
		margin-bottom: ${spacing(6)};

		&::before {
			transform: translateX(${spacing(-4)});
		}
	}

	@media (${media.desktop}) {
		max-width: ${toRem(430)};
		text-align: left;

		&::before {
			left: 0;
		}
	}
`;

export const Block = styled.div`
	&:first-child {
		margin-bottom: ${spacing(8)};
	}

	@media (${media.mobileAndTablet}) {
		max-width: ${toRem(570)};
		margin: 0 auto;
	}

	@media (${media.desktop}) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			width: calc(100% / 2 - ${spacing(4)});
		}

		&:nth-child(even) {
			${ContentBox} {
				order: 1;
			}
		}
	}
`;
